import { computed, unref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
    cloneDeep,
    get,
    mapValues,
    set,
    sumBy,
    toPairs,
    unset,
    isNil,
} from 'lodash-es'

export const useFilters = (queries, { replaceRoute = true } = {}) => {
    const route = useRoute()
    const router = useRouter()

    const queriesUnRef = unref(queries)
    const filterQueries = ref({})

    const originalQuery = computed(() => filterQueries.value)

    onMounted(() => {
        filterQueries.value = route.query
    })

    const filterValues = computed(() =>
        mapValues(queriesUnRef, (query) => {
            const nested = query.split('.')
            const path =
                nested.length > 2 ? [nested.shift(), nested.join('.')] : query
            return get(originalQuery.value, path)
        })
    )

    const filterCount = computed(() =>
        sumBy(toPairs(filterValues.value), (queryPair) =>
            typeof queryPair[1] === 'undefined' ? 0 : 1
        )
    )

    const updateFilter = async (
        queryPath,
        value = null,
        replace = replaceRoute
    ) => {
        const query = cloneDeep(originalQuery.value)
        upsertQuery(query, queryPath, value)

        filterQueries.value = query
        if (replace) {
            return router.replace({ query: query })
        }

        return query
    }

    const updateFilters = async (queries = {}, replace = replaceRoute) => {
        const query = cloneDeep(originalQuery.value)

        toPairs(queries).forEach(([queryPath, value]) => {
            upsertQuery(query, queryPath, value)
        })

        filterQueries.value = query
        if (replace) {
            return router.replace({ query: query })
        }

        return query
    }

    const resetFilters = (replace = replaceRoute) => {
        const query = cloneDeep(originalQuery.value)

        toPairs(queriesUnRef).forEach(([, queryPath]) => {
            const nested = queryPath.split('.')
            const path =
                nested.length > 2
                    ? [nested.shift(), nested.join('.')]
                    : queryPath
            unset(query, path)
        })

        filterQueries.value = query
        if (replace) {
            return router.replace({ query: query })
        }

        return query
    }

    const useRangeFilter = (
        queryKey,
        queryValue,
        isDateFilter = false,
        replace = replaceRoute
    ) => {
        const fromValue = ref(null)
        const toValue = ref(null)

        const queries = {
            [queryKey]: queryValue,
        }

        watch([fromValue, toValue], () => {
            const filter = {
                [queries[queryKey]]: null,
            }
            if (fromValue.value && toValue.value) {
                filter[queries[queryKey]] = [
                    'BETWEEN',
                    [fromValue.value, toValue.value],
                ]
            }

            if (fromValue.value && !toValue.value) {
                filter[queries[queryKey]] = [
                    `${isDateFilter ? 'DATE ' : ''}>=`,
                    fromValue.value,
                ]
            }

            if (!fromValue.value && toValue.value) {
                filter[queries[queryKey]] = [
                    `${isDateFilter ? 'DATE ' : ''}<=`,
                    toValue.value,
                ]
            }

            updateFilters(filter, replace)
        })

        onMounted(() => {
            if (!filterValues.value?.[queryKey]) return

            const [condition, range] = filterValues.value[queryKey]
            if (condition === 'BETWEEN') {
                const [date1, date2] = range
                fromValue.value = date1
                toValue.value = date2
            }

            if (condition === `${isDateFilter ? 'DATE ' : ''}>=`) {
                fromValue.value = range
            }

            if (condition === `${isDateFilter ? 'DATE ' : ''}<=`) {
                toValue.value = range
            }
        })

        const countTotal = (queryValue) => {
            if (!queryValue) {
                return 0
            }

            return Array.isArray(queryValue?.[1]) ? 2 : 1
        }

        return {
            fromValue,
            toValue,
            countTotal,
        }
    }

    const upsertQuery = (query, queryPath, value) => {
        const nested = queryPath.split('.')
        const path =
            nested.length > 2 ? [nested.shift(), nested.join('.')] : queryPath

        if (isNil(value) || value === '') {
            unset(query, path)
        } else {
            set(query, path, value)
        }
    }

    return {
        filterQueries,
        filterValues,
        filterCount,
        upsertQuery,
        updateFilter,
        updateFilters,
        resetFilters,
        useRangeFilter,
    }
}
